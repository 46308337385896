import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Flag from 'react-world-flags';

import SEO from '../components/seo';
import Layout from '../components/layout';

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        video
        meta
        classe
        category
        time
        affiche {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`;

const Film = ({ data }) => {
  const { html, frontmatter, excerpt, slug } = data.markdownRemark;
  const { title, affiche, video, meta, classe, category, time } = frontmatter;

  return (
    <Layout>
      <SEO
        title={`${title} - Cinélangues`}
        description={excerpt}
        pathname={slug}
        banner={affiche.childImageSharp.fluid}
        article
      />
      <div className="page">
        <div className="container">
          <div className="row no-gutter">
            <div className="card-picture col-12 col-lg-4 mb-2">
              <div className="col-6 offset-3 col-lg-12 offset-lg-0">
                <Img fluid={affiche.childImageSharp.fluid} alt={title} />
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <div className="header">
                <div className="col-12">
                  <h1>
                    {category === 'Espagnol' ? <Flag code="es" height="30" /> : null}
                    {category === 'Allemand' ? <Flag code="de" height="30" /> : null}
                    {category === 'Italien' ? <Flag code="it" height="30" /> : null}
                    {title}
                  </h1>
                  <p className="text-gray lead">
                    {category !== 'Archives' && time != 'NA' ? (
                      <span className="badge badge-pill badge-warning">
                        {classe}
                      </span>
                    ) : null}
                    {category !== 'Archives' && time != 'NA' ? (
                      <span className="badge badge-pill badge-lg badge-danger mr-1">
                        {time}
                      </span>
                    ) : null}
                    <small>
                      {meta}
                    </small>
                  </p>
                </div>
              </div>
              <div className="card card-video">
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    height="600"
                    width="100%"
                    title="video"
                    className="embed-responsive-item"
                    src={video}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
              <div className="card card-content">
                <div className="card-body p-3">
                  <div dangerouslySetInnerHTML={{ __html: html }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Film;

Film.propTypes = {
  data: PropTypes.object.isRequired,
};
